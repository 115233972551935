import { Head } from 'components/common';
import { PrivacyOptional } from 'components/policy';
import PolicyLayout from 'layout/policy-layout';

const PolicyPrivacyOptional = () => {
  return (
    <PolicyLayout>
      <Head title="개인정보보호 약정서(선택)" />
      <PrivacyOptional />
    </PolicyLayout>
  );
};

export default PolicyPrivacyOptional;
